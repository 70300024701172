import React from "react"
import SideBar from "react-sidebar"
// import { Motion, spring, presets } from "react-motion"
import Menu from "./menu"

class SideBarMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sidebarOpen: false,
      sidebarpullRight: true,
      toggle: false,
    }

    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this)
  }
  onSetSidebarOpen(state) {
    this.setState({ sidebarOpen: state.open, toggle: !this.state.toggle })
  }
  toggleMenu() {
    this.setState({
      sidebarOpen: !this.state.sidebarOpen,
      toggle: !this.state.toggle,
    })
  }

  handleKeyDown = ev => {
    // check keys if you want
    if (ev.keyCode === 13) {
      this.focus()
    }
  }

  focus = () => this.ref.focus

  handleRef = component => {
    this.ref = component
  }

  render() {
    // const style = {
    //  overflow: "visible",
    //  cursor: "pointer",
    //  // disable touch highlighting on devices
    //  WebkitTapHighlightColor: "rgba(0,0,0,0)",
    // }
    return (
      <SideBar
        sidebar={
          <Menu toggle={e => this.toggleMenu()} /> // Menu list
        }
        open={this.state.sidebarOpen}
        onSetOpen={this.onSetSidebarOpen}
        pullRight={this.state.sidebarpullRight}
        sidebarClassName="sidebar-menu"
        styles={{
          sidebar: {
            width: "300px",
            position: "fixed",
            zIndex: "3",
          },
          root: { position: "relative", overflow: "visible" },
          overlay: { backgroundColor: "rgba(0, 0, 0, 0.65)", zIndex: 2 },
          content: {
            position: "relative",
            overflow: "visible",
            cursor: "pointer",
          },
        }}
      >
        <button
          className="btn btn-nav"
          onClick={e => this.toggleMenu(e)}
          onKeyDown={e => this.handleKeyDown(e)}
        >
          <i className="fa fa-navicon"></i>
        </button>
      </SideBar>
    )
  }
}

export default SideBarMenu
