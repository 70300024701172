import React, { useState } from 'react';
import { Modal } from "react-bootstrap"
import {
    EmailShareButton,
    EmailIcon,
    FacebookShareButton,
    FacebookIcon,
    LinkedinShareButton,
    LinkedinIcon,
    TwitterShareButton,
    TwitterIcon,
  } from "react-share"

const SocialShareModal = () => {
    const [showModal, setShowModal] = useState(false);
    const currentUrl = typeof window !== "undefined" ? window.location.href : '';
    return (
        <>
            <button className="btn-social-share" onClick={()=>setShowModal(true)}>Share</button>
            <Modal
                show={showModal}
                onHide={()=>setShowModal(false)}
                dialogClassName="social-share-modal"
                centered
                >
                <Modal.Header closeButton>
                    <Modal.Title
                        style={{ letterSpacing: "2px", color: "#cc0000" }}
                        className="small text-uppercase d-block mb-2 mb-md-1 font-weight-bold"
                    >
                        Share Us on:
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <ul className="list-inline m-0">
                    <li className="list-inline-item">
                        <EmailShareButton url={currentUrl}>
                        <EmailIcon size={50} borderRadius={100} />
                        </EmailShareButton>
                    </li>
                    <li className="list-inline-item">
                        <FacebookShareButton url={currentUrl}>
                        <FacebookIcon size={50} borderRadius={100} />
                        </FacebookShareButton>
                    </li>
                    <li className="list-inline-item">
                        <TwitterShareButton url={currentUrl}>
                        <TwitterIcon size={50} borderRadius={100} />
                        </TwitterShareButton>
                    </li>
                    <li className="list-inline-item">
                        <LinkedinShareButton
                        url={currentUrl}
                        target="_blank"
                        rel="noreferrer"
                        >
                        <LinkedinIcon size={50} borderRadius={100} />
                        </LinkedinShareButton>
                    </li>
                    </ul>
                </Modal.Body>
                </Modal>
        </>
    );
};

export default SocialShareModal