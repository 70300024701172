import React from "react"
import { Link } from "gatsby"
import SideBarMenu from "../sidebar/sidebar"
import Sticky from "react-sticky-el"
import SocialShareModal from '../socialShareModal';

const Header = () => (
  <header>
    <div className="header-top-bar d-none d-md-block">
      <div className="container">
        <div className="d-flex justify-content-between">
          <ul className="list-inline social-icons-list">
            <li className="list-inline-item">
              <a
                href="http://www.facebook.com/FabEquipSalesCo"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-facebook"></i>
              </a>
            </li>
            <li className="list-inline-item">
              <a
                href="http://www.linkedin.com/company/2154517"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-linkedin"></i>
              </a>
            </li>
            <SocialShareModal />
          </ul>
          <div>
            <ul className="list-inline phone-nums">
              <li className="list-inline-item office">
                <a href="tel:303-466-7341">Office: (303) 466-7341</a>
              </li>
              <li className="list-inline-item mobile">
                <a href="tel:303-550-0734">Mobile: (303) 550-0734</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <Sticky
      className="header-wrapper"
      stickyClassName="is-sticky"
      stickyStyle={{ transform: "unset", zIndex: "2" }}
    >
      <div className="container">
        <div className="d-flex align-items-center justify-content-between">
          <Link to="/">
            <img
              className="header-logo"
              src={require("@images/logo-text.svg")}
              alt="Fabricating Equipment Sales Co."
            />
          </Link>
          <div className="d-flex align-items-center">
            {/* <a
              className="mr-4 cart d-flex align-items-center cart-link"
              href="#"
            ></a>
            <button className="btn btn-nav" type="button">
              <i className="fa fa-navicon"></i>
            </button>*/}
            <SideBarMenu />
          </div>
        </div>
      </div>
    </Sticky>
  </header>
)

export default Header
